import {
  type ResultWithOauthToken,
  type AppError,
  type Result,
  InvalidTokenError,
} from '@packages/utils';
import {
  useInfiniteQuery,
  useQuery,
  type UseInfiniteQueryResult,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {type z} from 'zod';
import {parseCookies} from 'nookies';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  CompanyListSchema,
  EngineerCompanyListSchema,
  GetFollowCompanyRankingSchema,
  GetGroupedCompanyMasterSchema,
  GetGroupedSupportCompanyMasterSchema,
  CompanyEntriesSchema,
  CompanyOrientationsSchema,
  GetIndustryCategoryMasterSchema,
  TopCompanyListSchema,
  GetIndustryMasterSchema,
  GetCompanyMasterSchema,
  type CompanySchema,
  type EngineerCompanySchema,
} from '../entities/company/schema';
import {
  FollowAdCompanyListSchema,
  UsersMeFollowedCompaniesResponse,
} from '../entities/usersMe/schema';
import {
  getCompaniesFromPages,
  getEngineerCompaniesFromPages,
} from '../factories/company/getCompaniesFromPage';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

/**
 * GetFollow
 */

export type GetFollowCompanyRankingResponse = z.infer<
  typeof GetFollowCompanyRankingSchema
>;
export type ResultGetFollowCompanyRanking = ResultWithOauthToken<
  GetFollowCompanyRankingResponse,
  AppError
>;

type GetFollowCompanyRankingArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getFollowCompanyRanking = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetFollowCompanyRankingArgs): Promise<ResultGetFollowCompanyRanking> => {
  const request = async (accessToken: string) => {
    if (accessToken) {
      defaultClient.setToken(accessToken);
    }

    const path = `/${version}/ranking/company/follow`;
    return defaultClient.get(path, GetFollowCompanyRankingSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
  });

  return result;
};

type UseGetFollowCompanyRankingOptions = {
  config?: UseQueryOptions<ResultGetFollowCompanyRanking>;
} & Omit<GetFollowCompanyRankingArgs, 'cookies'>;

export const useGetFollowCompanyRanking = ({
  config,
}: UseGetFollowCompanyRankingOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetFollowCompanyRanking>({
    ...config,
    queryKey: ['getFollowCompanyRanking'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getFollowCompanyRanking({cookies});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

/**
 * GetCompanyList
 */

export type Company = z.infer<typeof CompanySchema>;
export type GetCompanyListResponse = z.infer<typeof CompanyListSchema>;
export type ResultGetCompanyList = ResultWithOauthToken<
  GetCompanyListResponse,
  AppError
>;

type GetCompanyListArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  queryParams: {
    limit: number;
    page: number;
    order?: 'pageType';
    industryIDs: number[];
    industryCategoryIDs: number[];
    cacheID: string;
    minEmployeeCount?: number;
    maxEmployeeCount?: number;
  };
};

export const getCompanyList = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetCompanyListArgs): Promise<ResultGetCompanyList> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = createPathWithQuery(
      `/${version}/companies`,
      {...queryParams},
      'none',
    );
    return defaultClient.get(path, CompanyListSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
  });

  return result;
};

export type GetIndustryCategoryMaster = z.infer<
  typeof GetIndustryCategoryMasterSchema
>;
export type ResultGetIndustryCategoryMaster = Result<
  GetIndustryCategoryMaster,
  AppError
>;

type GetIndustryCategoryMasterParams = {
  version?: string;
  queryParams: {
    q?: string;
    limit?: number;
  };
};

export const getIndustryCategoryMaster = async ({
  version = DEFAULT_API_VERSION,
  queryParams: {q, limit},
}: GetIndustryCategoryMasterParams): Promise<ResultGetIndustryCategoryMaster> => {
  const params = {
    q: q ?? '',
    ...(limit ? {limit} : {}),
  };
  const path = createPathWithQuery(`/${version}/industry-categories`, params);
  const result = await defaultClient.get(path, GetIndustryCategoryMasterSchema);
  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCompanyListOptions = {
  config?: UseQueryOptions<ResultGetCompanyList>;
} & Omit<GetCompanyListArgs, 'cookies'>;

export const useGetCompanyList = ({
  queryParams,
  config,
}: UseGetCompanyListOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetCompanyList>({
    ...config,
    queryKey: ['companies'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCompanyList({cookies, queryParams});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type UseGetIndustryCategoryMasterOptions = {
  config?: UseQueryOptions<ResultGetIndustryCategoryMaster>;
} & GetIndustryCategoryMasterParams;

export const useGetIndustryCategoryMaster = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetIndustryCategoryMasterOptions) => {
  return useQuery<ResultGetIndustryCategoryMaster>({
    ...config,
    queryKey: ['getIndustryCategoryMaster', queryParams.q, queryParams.limit],
    async queryFn() {
      return getIndustryCategoryMaster({version, queryParams});
    },
  });
};

export const useInfiniteCompanyList = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetCompanyListOptions): UseInfiniteQueryResult<
  GetCompanyListResponse,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useInfiniteQuery(
    [`companyList`, queryParams],
    async ({pageParam = {page: 1, cacheID: ''}}) => {
      const cookies = parseCookies();
      const result = await getCompanyList({
        cookies,
        version,
        queryParams: {
          ...queryParams,
          page: pageParam.page,
          cacheID: pageParam.cacheID,
        },
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return getCompaniesFromPages(result);
    },
    {
      getNextPageParam: (lastPage) => ({
        page: lastPage?.nextURLParam?.page ?? 0,
        cacheID: lastPage?.nextURLParam?.cacheID ?? '',
      }),
      enabled: config?.enabled,
    },
  );
};

// 契約企業一覧
export type GetGroupedSupportCompanies = z.infer<
  typeof GetGroupedSupportCompanyMasterSchema
>;
export type ResultGetGroupedSupportCompanies = Result<
  GetGroupedSupportCompanies,
  AppError
>;

type GetGroupedSupportCompaniesParams = {
  version?: string;
  queryParams: {
    q?: string;
  };
};

export const getGroupedSupportCompanies = async ({
  version = DEFAULT_API_VERSION,
  queryParams: {q},
}: GetGroupedSupportCompaniesParams): Promise<ResultGetGroupedSupportCompanies> => {
  const path = `/${version}/companies/supported`;
  const result = await defaultClient.get(
    createPathWithQuery(path, {q: q ?? ''}),
    GetGroupedSupportCompanyMasterSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetGroupedSupportCompaniesOptions = {
  config?: UseQueryOptions<ResultGetGroupedSupportCompanies>;
} & GetGroupedSupportCompaniesParams;

export const useGetGroupedSupportCompanies = ({
  version = DEFAULT_API_VERSION,
  config,
  queryParams,
}: UseGetGroupedSupportCompaniesOptions) => {
  return useQuery<ResultGetGroupedSupportCompanies>({
    ...config,
    queryKey: ['getGroupedSupportCompanies', queryParams.q],
    async queryFn() {
      return getGroupedSupportCompanies({version, queryParams});
    },
  });
};

export type GetFollowAdCompanyList = z.infer<typeof FollowAdCompanyListSchema>;
export type ResultGetFollowAdCompanyList = Result<
  GetFollowAdCompanyList,
  AppError
>;

type GetFollowAdCompaniesQueryParams = {
  limit?: number;
  contractOnly?: boolean;
  gy?: number;
};

type GetFollowAdCompaniesParams = {
  version?: string;
  queryParams: GetFollowAdCompaniesQueryParams;
};

export const getFollowAdCompanies = async ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetFollowAdCompaniesParams): Promise<ResultGetFollowAdCompanyList> => {
  const path = `/${version}/advertisements/follow`;
  const param: GetFollowAdCompaniesQueryParams = {};
  if (queryParams.gy) {
    param.gy = queryParams.gy;
  }

  if (queryParams.limit) {
    param.limit = queryParams.limit;
  }

  if (queryParams.contractOnly) {
    param.contractOnly = queryParams.contractOnly;
  }

  const result = await defaultClient.get(
    createPathWithQuery(path, param),
    FollowAdCompanyListSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetFollowAdCompaniesOptions = {
  config?: UseQueryOptions<ResultGetFollowAdCompanyList>;
} & GetFollowAdCompaniesParams;

export const useGetFollowAdCompanies = ({
  version = DEFAULT_API_VERSION,
  config,
  queryParams,
}: UseGetFollowAdCompaniesOptions) => {
  return useQuery<ResultGetFollowAdCompanyList>({
    ...config,
    queryKey: ['getFollowAdCompanies', queryParams.gy, queryParams.limit],
    async queryFn() {
      return getFollowAdCompanies({version, queryParams});
    },
  });
};

export type UsersMeFollowedCompaniesResponse = z.infer<
  typeof UsersMeFollowedCompaniesResponse
>;

type UsersMeFollowedCompaniesResponseType = ResultWithOauthToken<
  UsersMeFollowedCompaniesResponse,
  AppError
>;

type GetUsersMeArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getUserFollowedCompanies = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetUsersMeArgs): Promise<UsersMeFollowedCompaniesResponseType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/followed-companies`,
      UsersMeFollowedCompaniesResponse,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
  });

  return result;
};

/**
 * GetCompanyEntries
 * 企業のエントリー情報を取得する
 * GET /3.0/companies/{companiId}/entries
 */

export type GetCompanyEntriesResponse = z.infer<typeof CompanyEntriesSchema>;
export type ResultGetCompanyEntries = ResultWithOauthToken<
  GetCompanyEntriesResponse,
  AppError
>;

type GetCompanyEntriesArgs = {
  companyId: number;
  previewCode?: string;
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getCompanyEntries = async ({
  companyId,
  previewCode,
  cookies,
  version = DEFAULT_API_VERSION,
}: GetCompanyEntriesArgs): Promise<ResultGetCompanyEntries> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const url = previewCode
      ? `/${version}/companies/${companyId}/entries?previewCode=${previewCode}`
      : `/${version}/companies/${companyId}/entries`;
    return defaultClient.get(url, CompanyEntriesSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
    allowReturnError: true,
  });

  return result;
};

/**
 * GetEngineerCompanyList
 */

export type EngineerCompany = z.infer<typeof EngineerCompanySchema>;
export type GetEngineerCompanyListResponse = z.infer<
  typeof EngineerCompanyListSchema
>;
export type ResultGetEngineerCompanyList = Result<
  GetEngineerCompanyListResponse,
  AppError
>;

type GetEngineerCompanyListArgs = {
  version?: string;
  queryParams: {
    limit: number;
    page: number;
    minEmployeeCount?: number;
    maxEmployeeCount?: number;
  };
};

export const getEngineerCompanyList = async ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetEngineerCompanyListArgs): Promise<ResultGetEngineerCompanyList> => {
  const path = createPathWithQuery(
    `/${version}/companies/engineer`,
    {...queryParams},
    'none',
  );
  const result = await defaultClient.get(path, EngineerCompanyListSchema);
  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetEngineerCompanyListOptions = {
  config?: UseQueryOptions<ResultGetEngineerCompanyList>;
} & GetEngineerCompanyListArgs;

export const useGetEngineerCompanyList = ({
  queryParams,
  config,
}: UseGetEngineerCompanyListOptions) => {
  return useQuery<ResultGetEngineerCompanyList>({
    ...config,
    queryKey: ['engineerCompanies'],
    async queryFn() {
      return getEngineerCompanyList({queryParams});
    },
  });
};

export const useInfiniteEngineerCompanyList = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetEngineerCompanyListOptions): UseInfiniteQueryResult<
  GetEngineerCompanyListResponse,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useInfiniteQuery(
    ['engineerCompanies', queryParams],
    async ({pageParam = 1}) => {
      const cookies = parseCookies();
      const result = await getEngineerCompanyList({
        version,
        queryParams: {
          ...queryParams,
          page: pageParam,
        },
      });

      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return getEngineerCompaniesFromPages(result);
    },
    {
      getNextPageParam: (lastPage) => ({
        page: lastPage?.nextPage ?? 0,
      }),
      enabled: config?.enabled,
    },
  );
};

type GetTopCompanyListArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  queryParams: {
    limit: number;
    is_only_top: boolean;
  };
};

export type GetTopCompanyListResponse = z.infer<typeof TopCompanyListSchema>;
export type ResultGetTopCompanyList = ResultWithOauthToken<
  GetTopCompanyListResponse,
  AppError
>;

export const getTopCompanyList = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetTopCompanyListArgs): Promise<ResultGetTopCompanyList> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = createPathWithQuery(`/${version}/companies/top`, {
      ...queryParams,
    });
    return defaultClient.get(path, TopCompanyListSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
  });

  return result;
};

type GetIndustryMaster = z.infer<typeof GetIndustryMasterSchema>;
export type ResultGetIndustryMaster = Result<GetIndustryMaster, AppError>;

type GetIndustryMasterParams = {
  version?: string;
  queryParams: {
    q?: string;
    limit?: number;
  };
};

export const getIndustryMaster = async ({
  version = DEFAULT_API_VERSION,
  queryParams: {q, limit},
}: GetIndustryMasterParams): Promise<ResultGetIndustryMaster> => {
  const params = {
    q: q ?? '',
    ...(limit ? {limit} : {}),
  };
  const path = createPathWithQuery(
    `/${version}/service/master/industries`,
    params,
  );
  const result = await defaultClient.get(path, GetIndustryMasterSchema);
  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type GetCompanyEntriesOptions = {
  config?: UseQueryOptions<ResultGetCompanyEntries>;
} & Omit<GetCompanyEntriesArgs, 'cookies'>;

export const useGetCompanyEntries = ({
  companyId,
  previewCode,
  version = DEFAULT_API_VERSION,
  config,
}: GetCompanyEntriesOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetCompanyEntries>({
    ...config,
    queryKey: ['getCompanyEntries', companyId],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCompanyEntries({
        companyId,
        previewCode,
        cookies,
        version,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

/**
 * GetCompanyOrientations
 * 企業の説明会情報を取得する
 * GET /3.0/companies/{companiId}/orientations
 * @param companyId
 * @param previewCode
 */

export type GetCompanyOrientationsResponse = z.infer<
  typeof CompanyOrientationsSchema
>;
export type ResultGetCompanyOrientations = ResultWithOauthToken<
  GetCompanyOrientationsResponse,
  AppError
>;

type GetCompanyOrientationsArgs = {
  companyId: number;
  previewCode?: string;
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getCompanyOrientations = async ({
  companyId,
  previewCode,
  cookies,
  version = DEFAULT_API_VERSION,
}: GetCompanyOrientationsArgs): Promise<ResultGetCompanyOrientations> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const url = previewCode
      ? `/${version}/companies/${companyId}/orientations?previewCode=${previewCode}`
      : `/${version}/companies/${companyId}/orientations`;
    return defaultClient.get(url, CompanyOrientationsSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
    allowReturnError: true,
  });

  return result;
};

type UseGetTopCompanyListOptions = {
  config?: UseQueryOptions<ResultGetTopCompanyList>;
} & Omit<GetTopCompanyListArgs, 'cookies'>;

export const useTopCompanyList = ({
  queryParams,
  config,
}: UseGetTopCompanyListOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetTopCompanyList>({
    ...config,
    queryKey: ['topCompanies'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getTopCompanyList({cookies, queryParams});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type UseGetIndustryMasterOptions = {
  config?: UseQueryOptions<ResultGetIndustryMaster>;
} & GetIndustryMasterParams;

export const useGetIndustryMaster = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetIndustryMasterOptions) => {
  return useQuery<ResultGetIndustryMaster>({
    ...config,
    queryKey: ['getIndustryMaster', queryParams.q, queryParams.limit],
    async queryFn() {
      return getIndustryMaster({version, queryParams});
    },
  });
};

// GET /service/master/grouped-companies
export type GetGroupedCompanies = z.infer<typeof GetGroupedCompanyMasterSchema>;
export type ResultGetGroupedCompanies = Result<GetGroupedCompanies, AppError>;

type GetGroupedCompaniesParams = {
  version?: string;
  queryParams: {
    q?: string;
    limit?: number;
  };
};

export const getGroupedCompanies = async ({
  version = DEFAULT_API_VERSION,
  queryParams: {q, limit},
}: GetGroupedCompaniesParams): Promise<ResultGetGroupedCompanies> => {
  const params = {
    q: q ?? '',
    ...(limit ? {limit} : {}),
  };
  const path = createPathWithQuery(
    `/${version}/service/master/grouped-companies`,
    params,
  );
  const result = await defaultClient.get(path, GetGroupedCompanyMasterSchema);

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type GetCompanyOrientationsOptions = {
  config?: UseQueryOptions<ResultGetCompanyOrientations>;
} & Omit<GetCompanyOrientationsArgs, 'cookies'>;

export const useGetCompanyOrientations = ({
  companyId,
  previewCode,
  version = DEFAULT_API_VERSION,
  config,
}: GetCompanyOrientationsOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetCompanyOrientations>({
    ...config,
    queryKey: ['getCompanyOrientations', companyId],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCompanyOrientations({
        companyId,
        previewCode,
        cookies,
        version,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type UseGetGroupedCompaniesOptions = {
  config?: UseQueryOptions<ResultGetGroupedCompanies>;
} & GetGroupedCompaniesParams;

export const useGetGroupedCompanies = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetGroupedCompaniesOptions) => {
  return useQuery<ResultGetGroupedCompanies>({
    ...config,
    queryKey: ['getGroupedCompanies', queryParams.q, queryParams.limit],
    async queryFn() {
      return getGroupedCompanies({version, queryParams});
    },
  });
};

type GetCompanyMaster = z.infer<typeof GetCompanyMasterSchema>;
export type ResultGetCompanyMaster = Result<GetCompanyMaster, AppError>;

type GetCompanyMasterParams = {
  version?: string;
  queryParams: {
    q?: string;
    limit?: number;
  };
};

export const getCompanyMaster = async ({
  version = DEFAULT_API_VERSION,
  queryParams: {limit},
}: GetCompanyMasterParams): Promise<ResultGetCompanyMaster> => {
  const params = {
    q: '',
    ...(limit ? {limit} : {}),
  };
  const path = createPathWithQuery(`/${version}/master/companies`, params);
  const result = await defaultClient.get(path, GetCompanyMasterSchema);
  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCompanyMasterOptions = {
  config?: UseQueryOptions<ResultGetCompanyMaster>;
} & GetCompanyMasterParams;

export const useGetCompanyMaster = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetCompanyMasterOptions) => {
  return useQuery<ResultGetCompanyMaster>({
    ...config,
    queryKey: ['getCompanyMaster', queryParams.q, queryParams.limit],
    async queryFn() {
      return getCompanyMaster({version, queryParams});
    },
  });
};
